@import "tailwindcss/base";

@import "tailwindcss/components";

@import "tailwindcss/utilities";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
html {
  font-size: 62.5%;
}
/*************************************************************************************************/
/**********navbar**********************************************************************************/
.nav-container {
  position: sticky;
  top: 0;
  z-index: 10;
}
.navbar {
  max-width: 75rem;
  margin: 0 auto;
}

/*****************************************************************************************************/
/************send-message********************************************************************************/
.send-message {
  width: 100%;
  position: fixed;
  bottom: 0;
  padding: 4rem;
}
.form {
  max-width: 75rem;
  margin: 0 auto;
  display: flex;
  justify-content: center;
}
.input {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-send {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}
/************************************************************************************************/
/***********chat-box******************************************************************************/
.chat-box-container {
  max-width: 75rem;
  margin: 0 auto 10rem auto;
}
.messages-container {
  padding: 2rem;
}
